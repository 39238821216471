import Seo from 'src/components/seo/Seo'

import NewAboutUs from '../../components/Institucional/NewAboutUs'

function AboutDecathlon() {
  return (
    <>
      <Seo title="Institucional | Decathlon" />
      <NewAboutUs />
    </>
  )
}

export default AboutDecathlon
